/* General about section styling */
.about-section {
  padding: 4rem 10rem;
}

.about-heading {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.about-para {
  font-size: 1rem;
  padding-bottom: 2rem;
  color: gray;
  line-height: 1.6;
}

.about-image-container {
  width: 100%;
  /* height: 100%; */
  margin-bottom: 5rem;
  /* text-align: right; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.img-about {
  width: 40%;
  margin: auto;
}
.about-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: center;
}

.about-text {
  font-size: 1.2rem;
}

.about-icons {
  background: #006aa7;
  width: auto;
  margin: auto 0;
  padding: 1rem;
  border-radius: 20%;
  display: flex;
  align-items: center;
}

.about-icons img {
  max-width: 50px;
  height: auto;
  margin-right: 1rem;
}

.about-section-heading {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about-detail-para {
  font-size: 1rem;
  margin-bottom: 0;
  color: gray;
}

/* Media query for small screens */
@media (max-width: 850px) {
  .about-section {
    padding: 2rem 2rem;
  }

  .about-grid {
    grid-template-columns: none;
  }
}

@media (min-width: 850px) and (max-width: 1100px) {
  .about-section {
    padding: 2rem 3rem;
  }
}

/* RTL specific styles */
.rtl .about-grid {
  grid-template-columns: 1fr 1fr; /* Keep grid structure */
  direction: rtl; /* Set right-to-left direction */
}

.rtl .about-text {
  text-align: right; /* Right-align text for RTL */
}

.rtl .about-image-container {
  order: -1; /* Place the image on the left side when RTL */
}

.rtl .about-image-container img {
  margin-right: 0; /* Remove right margin for RTL */
  margin-left: 20px; /* Add left margin for RTL */
}

/* LTR specific styles */
.ltr .about-grid {
  grid-template-columns: 1fr 1fr; /* Keep grid structure */
  direction: ltr; /* Set left-to-right direction */
}

.ltr .about-text {
  text-align: left; /* Left-align text for LTR */
}

.ltr .about-image-container img {
  margin-left: 20px; /* Add margin for LTR */
}
