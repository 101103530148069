.vertical-slider-container {
  width: 80px;
  /* Adjust this width as necessary */
  height: 100%;
  max-height: 700px;
  overflow-y: scroll;
  /* Makes the slider scrollable */
  scrollbar-width: none;
  /* Thin scrollbar for Firefox */
}

.vertical-slider {
  display: flex;
  flex-direction: column;
  /* Arrange images vertically */
  gap: 10px;
  /* Space between images */
  width: 100%;
}

.vertical-slider-item {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.vertical-slider-item img {
  width: 100%;
  /* Full width for thumbnails */
  display: block;
  /* Ensures images do not overflow */
  object-fit: cover;
  /* Keeps image aspect ratio */
  max-height: 100px;
  /* Maximum height for each thumbnail */
}

.vertical-slider-item:hover {
  transform: scale(1.1);
  /* Enlarge thumbnail on hover */
}

.vertical-slider::-webkit-scrollbar {
  width: 2px;
}

.vertical-slider::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.vertical-slider::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

@media (max-width: 850px) {
  .vertical-slider-container {
    width: 100%;
    max-width: 310px;
    height: 50px;
    overflow-x: scroll;
    scrollbar-width: none;
    margin-top: 15px;
  }

  .vertical-slider {
    display: flex;
    flex-direction: row;
    /* Arrange images vertically */
    gap: 10px;
    /* Space between images */
    height: 50px;
    width: 100%;
  }

  .vertical-slider-item img {
    width: 50px;
    /* Full width for thumbnails */
    display: block;
    /* Ensures images do not overflow */
    object-fit: cover;
    /* Keeps image aspect ratio */
    height: 50px;
    /* Maximum height for each thumbnail */
  }
}