/* Main product detail page styles */
.product-detail-page {
    padding: 4rem 10rem;
}

.product-top-section {
    display: grid;
    grid-template-columns: 3fr 2fr; /* Images on the left (60-70%) and info on the right */
    gap: 2rem;
    padding: 1rem 0;
}

/* Customer Reviews Section Styles */
.customer-reviews-section {
    margin-top: 2rem;
}

.tab-content{ 
    padding: 2rem;
}

.customer-reviews-section h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #333;
}

.reviews-slider-container {
    display: flex;
    align-items: center;
    position: relative;
}

.reviews-slider {
    display: flex;
    overflow-x: hidden;
    gap: 1rem;
    padding: 1rem 0;
    scroll-snap-type: x mandatory;
    flex-grow: 1;
}

.review-card {
    flex: 0 0 auto;
    width: 250px; /* Set a fixed width for the review cards in the slider */
    scroll-snap-align: start;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.reviewer-name {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.review-rating {
    color: #ffd700; /* Gold color for stars */
    margin-bottom: 0.5rem;
}

.review-comment {
    font-style: italic;
    color: #555;
}

/* People Also Bought Section Styles */
.product-related-section {
    margin-top: 2rem;
}

.product-related-section h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #333;
}

.product-slider-container {
    display: flex;
    align-items: center;
    position: relative;
}

.product-slider {
    display: flex;
    overflow-x: hidden;
    gap: 1rem;
    padding: 1rem 0;
    scroll-snap-type: x mandatory;
    flex-grow: 1;
}

/* Adjust the width of the product cards */
.product-component-card {
    flex: 0 0 auto; /* Keep this to maintain the horizontal layout */
    scroll-snap-align: start;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s;
    width: 300px; /* Reduced width for the product cards */
}

.product-component-card:hover {
    transform: scale(1.05);
}

/* Scroll button styles */
.scroll-button {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 1.5rem;
    margin: 0 0.5rem;
    border-radius: 50%;
    transition: background-color 0.3s;
    z-index: 10;
}

.scroll-button:hover {
    background-color: #555;
}

.MuiAccordion-root {
    margin: 1.5rem 0;
    box-shadow: none;
}

.MuiAccordionSummary-root {
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 10px 15px;
}

.MuiAccordionDetails-root {
    padding: 15px;
}

.category-section {
    margin-bottom: 2rem; /* Space below the category section */
}

.category-section h1 {
    font-size: 1.5rem; /* Size for the category title */
    color: #333; /* Text color */
    margin: 0; /* Remove default margin */
    display: inline; /* Keep category and subcategory inline */
}

.category-section span {
    font-size: 1.5rem; /* Size for the subcategory title */
    color: #666; /* Slightly lighter text color for subcategory */
    margin-left: 0.5rem; /* Space between category and subcategory */
}

/* Responsive styles */
@media (max-width: 850px) {
    .product-top-section {
        grid-template-columns: 1fr; /* Single column on smaller screens */
        gap: 1rem;
        padding: 1rem 0;
    }
    .product-detail-page {
        padding: 2rem 2rem;
    }

    .product-component-card {
        width: 150px; /* Further reduce for mobile */
    }
}

@media (min-width: 850px) and (max-width: 1200px) {
    .product-component-card {
        width: 300px; /* Adjust for medium-sized screens */
    }
}
