.checkout-nav {
    background-color: rgb(248 249 250);
    padding: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.continue-shopping {
    font-size: 1rem;
    cursor: pointer;
    margin: 0 !important;
}

.checkout-heading {
    font-size: 1.4rem;
    font-weight: 500;
}

.eighteen-plus {
    font-size: 1rem;
    padding: 0.5rem;
    border: 2px black solid;
    border-radius: 50%;
}

.checkout-grid {
    display: flex;
    flex-direction: column; /* Ensure full width for CheckoutCart */
    padding: 2rem 10rem;
    gap: 2rem;
}

.checkout-grid-template {
    padding: 1rem !important;
    width: 100%; /* Ensure CheckoutCart takes the full width */
}

.checkout-cart-products {
    height: 100% !important;
}

.checkout-subheading {
    font-size: 1rem;
}

.checkout-p {
    font-size: 1rem;
}

p.customer-login {
    color: #22664D;
    font-weight: bold;
    text-align: center;
}

p.customer-login a {
    color: #11558d;
    text-decoration: underline;
}

p.customer-login a:hover {
    text-decoration: none;
}

.place-order .btn {
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: normal;
}

@media (max-width: 850px) {
    .checkout-nav {
        flex-direction: column-reverse;
        padding: 0.2rem;
    }

    .eighteen-plus {
        padding: 0.2rem;
        font-size: 0.6rem;
    }

    .checkout-grid {
        padding: 1rem;
        gap: 1rem;
    }

    .checkout-grid-template {
        padding: 0.2rem !important;
    }
}

@media (min-width: 850px) and (max-width: 1100px) {
    .checkout-grid {
        padding: 1rem 3rem;
        gap: 1.5rem;
    }

    .checkout-grid-template {
        padding: 0.75rem !important;
    }
}
