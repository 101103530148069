.product-details-images-container {
    display: grid;
    grid-template-columns: 20% 80%; /* Set the slider to 20% and the main image to 80% */
    text-align: center;
    max-height: 100%;
    overflow: hidden; /* Prevent any overflow from the container */
}

.product-details-slider {
    overflow-y: none;
    overflow-x: none;
    width: 100%;
     /* Enable vertical scrolling for the slider */
    max-height: 100%; /* Ensure the slider does not exceed the height of the container */
}

.small-product-details-img {
    width: 100%; /* Thumbnails should take full width of the slider container */
    margin-bottom: 5px; /* Add spacing between thumbnails */
    cursor: pointer;
}

.product-details-image {
    height: auto;
    position: relative;
    cursor: pointer; /* Indicate that the image is clickable */
}

.product-details-image img {
    object-fit: cover;
    width: 100%;
    height: auto;
    background-color: #F3F1E5;
    transition: transform 0.3s ease; /* Smooth transition for zoom effect */
}

.product-details-image:hover img {
    transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8); /* Dark background for modal overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above all other content */
}

.modal-content {
    position: relative;
    width: 70vw; /* Set the modal content to 70% of the viewport width */
    height: 70vh; /* Set the modal content to 70% of the viewport height */
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    border-radius: 8px;
    cursor: zoom-in; /* Cursor changes to zoom-in when image is not zoomed */
    transition: transform 0.3s ease; /* Smooth transition for zoom effect */
}

.modal-content img.zoomed {
    transform: scale(1.5); /* Zoom the image by 1.5 times */
    cursor: zoom-out; /* Cursor changes to zoom-out when image is zoomed */
}

.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
    z-index: 1001;
    transition: color 0.3s;
}

.modal-close-button:hover {
    color: #ddd;
}

/* Responsive Styles */
@media (max-width: 850px) {
    .product-details-images-container {
        display: flex;
        flex-direction: column-reverse; /* Stacks the slider below the main image on small screens */
        text-align: center;
        overflow: hidden; /* Prevent any overflow from the container */
    }

    .product-details-image {
        display: inline;
    }

    .product-details-image img {
        position: relative;
        margin-bottom: 1rem;
    }
}

@media (min-width: 850px) and (max-width: 1100px) {
    /* Adjust styles as needed for intermediate screen sizes */
}
