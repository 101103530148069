/* General Cart Modal Styling */
.cart-modal {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%; /* Change from 400px to 100% for responsive design */
  max-width: 400px; /* Limit maximum width */
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 9999;
  overflow-y: auto; /* Add vertical scrolling if content overflows */
}

.cart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  pointer-events: auto;
  overflow: hidden; /* Ensure it doesn’t contribute to scrolling */
}

.recommended-product-card {
  display: flex !important;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  gap: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 10px 0;
}

.product-details{ 
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.cart-modal.open {
  transform: translateX(0);
}

.cart-modal-content {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}

.cart-heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

/* Cart Items Section */
.cart-items-container {
  margin-bottom: 20px;
}

.empty-cart {
  text-align: center;
  font-size: 16px;
  color: #555;
  border-color: #555;
}

/* Pricing Section */
.cart-pricing {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 16px;
}

/* Recommended Products Section */
.recommended-heading {
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0;
}

.recommended-products {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.recommended-product {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: box-shadow 0.2s;
}

.recommended-product:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Image Styling */
.recommended-product-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

/* Product Details */
.recommended-product-details {
  margin-left: 15px;
  flex-grow: 1;
}

/* Add to Cart Button */
.btn-primary {
  padding: 5px 10px;
  background-color: #1b84c0;
  border: none;
  color: white;
  font-size: 13px;
  border-radius: 3px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #588dac;
}

/* Checkout Button Styling */
.checkout-btn {
  background-color: #3d85ac; /* Enabled color */
  border: none;
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.checkout-btn:hover:not(:disabled) {
  background-color: #2a4d5e; /* Darker shade on hover */
}

.checkout-btn:disabled {
  background-color: #bbb; /* Gray when disabled */
  cursor: not-allowed;
}

/* Continue Shopping Button Styling */
.continue-shopping-btn {
  background-color: transparent; /* Remove background color */
  border: 2px solid #3d85ac; /* Border with the original background color */
  color: #3d85ac; /* Text color to match the original background */
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.continue-shopping-btn:hover {
  background-color: #3d85ac; /* Background color on hover */
  color: white;  /* Darker shade on hover */
}

/* Updated Cart Actions Section */
.cart-actions {
  display: flex;
  justify-content: space-between;
  gap: 15px; /* Spacing between buttons */
  padding: 10px 0;
}

.continue-shopping-btn,
.checkout-btn {
  flex: 1; /* Ensure both buttons take equal space */
  max-width: 48%; /* Prevent buttons from stretching too much */
}

/* Utility Classes */
.no-scroll {
  overflow: hidden;
}

.my-3 {
  margin: 15px 0;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.ms-3 {
  margin-left: 15px;
}

.ms-auto {
  margin-left: auto;
}

.btn {
  cursor: pointer;
}

/* Overlay Styling */
.cart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black */
  z-index: 999;
  /* Should be lower than the cart modal */
  transition: opacity 0.3s ease;
  opacity: 0;
  /* Hidden by default */
  pointer-events: none;
  /* Disable interactions when hidden */
}

.cart-overlay.open {
  opacity: 1;
  /* Show when cart is open */
  pointer-events: auto;
  /* Enable interactions */
}

@media (max-width: 375px) {
  .cart-modal {
    width: 100%;
    /* Full width for mobile devices */
  }
}