/* General Styles */
body {
  font-family: "Roboto", sans-serif;
  background-color: #f4f4f4;
  color: #333;
}


/* Header */
.checkout-nav {
  background-color: #fff;
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.checkout-heading {
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0;
  color: #333;
}

/* Main Grid Layout */
.cart-page-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 2rem 10rem;
  gap: 2rem;
}

/* Cart Items Section */
.cart-items-section {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 1.5rem;
  border-radius: 4px;
}

.section-heading {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #0058a3;
}

/* Divider */
.divider {
  margin: 1.5rem 0;
  border-color: #ddd;
}

/* Discount Code Block */
.discount-code-block {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.discount-input {
  flex-grow: 1;
}

.apply-button {
  background-color: #0058a3;
  color: #fff;
  text-transform: none;
  box-shadow: none;
}

/* Cart Total Section */
.cart-total-section {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 1.5rem;
  border-radius: 4px;
}

/* Checkout Button */
.checkout-button {
  background-color: #0058a3;
  color: #fff;
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  border: none;
  text-transform: none;
  font-size: 16px;
}

/* Media Queries */
@media (max-width: 850px) {
  .checkout-nav {
    flex-direction: column-reverse;
  }

  .cart-page-grid {
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }

  .checkout-grid-template {
    padding: 1rem !important;
  }
}

@media (min-width: 850px) and (max-width: 1100px) {
  .checkout-grid {
    padding: 2rem 5rem;
  }

  .checkout-grid-template {
    padding: 1rem !important;
  }
}
