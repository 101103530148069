/* Login Form CSS */
.login-form-container {
  background-color: #f8f9fa; /* Background of the form */
  padding: 3rem;
  width: 40%;
  margin: 3rem auto;
}

.loginpage_heading {
  font-family: "Courier New", Courier, monospace;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.input-group {
  margin-bottom: 1rem;
}

.login-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

.signup-text {
  margin: 1rem 0;
  font: italic;
}

.signin_link {
  color: #006aa7;
}

.signin_link:hover {
  color: #066fac;
  text-decoration: underline;
}

/* CSS */
.checkbox-input {
  accent-color: #0e675e; /* Change the background color */
}

.fix-width-input {
  width: 100% !important;
}

.MuiFormControl-root {
  width: 100% !important;
}

.btn-login {
  margin-top: 2rem;
}

.login-form-container {
  direction: ltr; /* Default direction */
  text-align: left; /* Default alignment */
}

.login-form-container.rtl {
  direction: rtl; /* Enable right-to-left direction */
  text-align: right; /* Align text to the right */
}

.input-group {
  margin-bottom: 20px;
}

.login-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-flex.rtl {
  flex-direction: row-reverse; /* Reverse the flex direction */
}

.checkbox-group {
  display: flex;
  align-items: center;
}

.checkbox-group.rtl {
  flex-direction: row-reverse;
}

.signin_link {
  text-decoration: none;
  color: #007bff;
}

.signin_link:hover {
  text-decoration: underline;
}
/* General RTL Direction */
.rtl {
  direction: rtl;
}

/* Checkbox and Label Alignment */
.checkbox-group {
  display: flex;
  align-items: center;
  gap: 10px; /* Default gap between checkbox and text */
}

.rtl .checkbox-group {
  justify-content: flex-end;
}

.rtl-checkbox {
  margin-left: 10px; /* Add margin on the left for RTL */
  margin-right: 0; /* Reset right margin */
}

.rtl-label {
  margin-left: 0;
  margin-right: 10px; /* Add space between label and checkbox for RTL */
}

@media (max-width: 850px) {
  .login-form-container {
    width: 100%;
    margin: 0;
    background-color: #fff;
  }
}

@media (min-width: 850px) and (max-width: 1100px) {
  .login-form-container {
    width: 60%;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotating-loader {
  animation: rotate 3s linear infinite;
}
