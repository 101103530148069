/* Center the entire page with a max-width and margins */
.product-search-container {
  display: flex;
  padding: 20px;
  gap: 20px;
  max-width: 1200px; /* Limit the width for a centered look */
  margin: 0 auto; /* Center the page */
}

/* Left sidebar background and padding */
.left-sidebar {
  width: 30%;
  display: flex;
  flex-direction: column;

  background-color: #f7f7f7; /* Light background similar to Shein's sidebar */
  padding: 15px; /* Add padding for space */
  border-radius: 8px; /* Rounded corners for a modern look */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05); /* Soft shadow for elevation */
}

/* Right content styling */
.right-content {
  width: 70%;
  display: flex;
  flex-direction: column;
}

/* Product grid styling */
.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.dropdown-header {
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 12px;
  color: #333;
  background: #ececec;
  border-radius: 5px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between; /* Align icon to the right */
  align-items: center;
  transition: background 0.3s, color 0.3s;
}

.dropdown-header:hover {
  background: #e0e0e0;
  color: #4dc1f7;
}

/* Dropdown List Styles */
.dropdown-list {
  list-style: none;
  background: #ffffff;
  border-radius: 5px;
  padding: 0;
  margin: 10px 0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  max-height: 0;
  overflow-y: hidden; /* Hide overflow initially */
  transition: max-height 0.3s ease;
}

.dropdown-list.expanded {
  max-height: 200px; /* Expanded height when opened */
  overflow-y: auto;
}

/* Custom Scrollbar for Dropdown */
.dropdown-list.expanded::-webkit-scrollbar {
  width: 8px;
}

.dropdown-list.expanded::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 4px;
}

.dropdown-list.expanded::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

/* List Items */
.dropdown-list li {
  padding: 10px 20px;
  list-style: none;
  transition: background 0.3s;
}

.dropdown-list li:hover {
  background: #f5f5f5;
}

/* Category and Brand Button Styles */
.categories button,
.brands button {
  background: transparent;
  border: none;
  color: #333;
  width: 100%;
  text-align: left;
  padding: 8px 20px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s, color 0.3s;
}

.categories button.selected,
.brands button.selected {
  color: #4dc1f7;
  font-weight: bold;
}

.categories button:hover,
.brands button:hover {
  color: #4dc1f7;
  background: #f7f7f7;
}

/* Clear Filters Button */
.clear-filters {
  margin-top: 20px;
  padding: 10px;
  background: #f87647;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;
  width: 100%;
}

/* Label styles for better alignment */
label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

input[type="checkbox"] {
  margin-right: 10px; /* Space between checkbox and text */
}

/* Selected State Styles */
.dropdown-list li.selected h3,
.dropdown-list li.selected button,
.categories button.selected,
.brands button.selected {
  background-color: #e0f7fa; /* Change to your preferred color */
  color: #00796b; /* Change to your preferred color */
}

/* Optional: Make sure selected items are highlighted on hover as well */
.dropdown-list li.selected:hover h3,
.dropdown-list li.selected:hover button {
  background-color: #b2ebf2; /* Change to your preferred hover color */
}

.full-width-input {
  width: 100% !important;
}

.MuiFormControl-root {
  width: 100% !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .product-search-container {
    flex-direction: column;
  }

  .left-sidebar,
  .right-content {
    width: 100%;
  }
}
