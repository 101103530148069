.navbar {
  padding-left: 10rem;
  padding-right: 10rem;
  box-shadow: none !important;
  overflow: unset;
  transition: 250ms ease-in-out;
  background: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.divider {
  border-bottom: 1px solid #ddd;
  margin: 0.5rem 0;
}

.navbar-toggler {
  border: none;
  position: absolute;
  right: 0px;
}

#navbarSupportedContent {
  justify-content: center;
}

.nav-item {
  position: relative;
  /* padding: 0.1rem 0; */
}

.navbar-transparent {
  background-color: #00000000 !important;
  color: #fff !important;
}

.navbar-transparent .nav-link {
  color: #fff !important;
}

.navbar-transparent .nav-link:hover {
  color: #d9cdcd !important;
}

.container-fluid {
  display: grid !important;
  grid-template-columns: 2fr 3fr 2fr;
}

.page-top {
  background-color: #FF914D;
  padding: 0.6rem 0;
  color: #fff;
  font-size: 0.8rem;
  transition: opacity 0.5s ease-in-out;
  /* Fade effect */
}

.message {
  padding: 0;
  /* Remove padding */
  text-align: center;
  /* Center text horizontally */
  margin: 0;
}

/* Optionally add additional styles for the paragraph or messages */

.navbar-brand {
  /* width: 5rem; */
}

.logo {
  width: 5rem;
  height: 100%;
}

.cart-icon-container {
  position: relative;
  display: inline-block;
}

.cart-counter {
  position: absolute;
  top: 0px;
  right: -2px;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  padding: 1px 2px;
  font-size: 10px;
  min-width: 50%;
  text-align: center;
}

.nav-link {
  color: #000;
  font-size: 1rem;
  font-weight: normal;
  margin: 0 1rem;
  padding: 1rem 0;
}

.nav-link-mega {
  color: #000;
  font-size: 1rem;
  font-weight: normal;
  margin: 0 1rem;
  padding: 1rem 0;
  padding-top: 1.1rem;
}

.icons-container {
  /* margin: auto 1rem auto auto; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.nav-icon-container {
  margin-right: 1rem !important;
}

.nav-icons {
  cursor: pointer;
}

.nav-icons:hover {
  color: #616161 !important;
}

.MuiPaper-root {
  background-color: #f8f9fa !important;
}

.MuiPaper-root ul {
  padding: 0;
}

.MuiInputBase-root {
  width: 100% !important;
}

#search-box {
  visibility: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999999;
}

#search-box .container {
  display: table;
  width: 100%;
  height: 100%;
}

#search-box .container .close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}

#search-box .container .close:hover {
  opacity: 1;
}

#search-box .container .close:before,
#search-box .container .close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: white;
}

#search-box .container .close:before {
  transform: rotate(45deg);
}

#search-box .container .close:after {
  transform: rotate(-45deg);
}

#search-box .container .search-main {
  display: table-cell;
  vertical-align: middle;
}

#search-box .container .search-main .search-inner {
  width: 80%;
  margin: 0 auto;
}

#search-box .container .search-main .search-inner form {
  position: relative;
}

#search-box .container .search-main .search-inner input {
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 12px 74px 12px 0;
  font-size: 1.5rem;
  height: 84px;
  border-bottom: 2px solid white;
  display: block;
  color: white;
  margin-bottom: 0;
  width: 100%;
  transform: scale3d(0, 1, 1);
  transform-origin: 0% 50%;
  transition: transform 3s;
}

#search-box .container .search-main .search-inner input:focus {
  outline: none;
}

#search-box .container .search-main .search-inner .search-info {
  display: block;
  color: white;
  text-align: right;
  float: right;
  margin-top: 4px;
}

#search-box.-open {
  background: #000000e3;
  visibility: visible;
  animation: fadein 0.8s;
}

#search-box.-open .container .search-main .search-inner input {
  transform: scale3d(1, 1, 1);
  transition-duration: 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.small-items {
  display: none !important;
}

.large-items {
  display: block !important;
}

.custom-search-button {
  background-color: #FF914D;
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  border-left: 3px solid black;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-right: none;
  border-radius: 25px 0 0 25px;
  text-transform: capitalize;
}

.custom-search-container {
  display: flex;
  align-items: center;
  border: 2px solid black;
  border-radius: 25px;
  background-color: #fff;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.custom-search-container .custom-search-button {
  flex-shrink: 0;
  /* Button size ko fix rakha */
}



.custom-search-input {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 10px 15px;
  font-size: 14px;
  color: #333;
}

.custom-search-input::placeholder {
  color: #999;
}

.custom-search-container:focus-within {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

@media (min-width: 992px) {
  .divider {
    display: none;
  }
}


@media (max-width: 558px) {
  .logo {
    width: 3rem;
    height: 100%;
  }
}

/* 
@media (min-width: 991px) {
  .logo,
  .navbar-brand {
    width: 100% !important;
    height: 100%;
  }
} */
/* @media (min-width: 355px) {
  .navbar-brand {
    width: 100% !important;
    height: 100%;
  }
} */

@media (max-width: 991px) {
  .navbar {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }

  /* Adjusting icons container for 3/3 structure */
  .icons-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* 3 icons per row */
    /* gap: 0.2rem; Adjust gap between icons */
    justify-items: center;
    /* Center icons in their grid cells */
    width: 100%;
    /* Make sure the container takes full width */
    /* padding: 1rem 0; Add some padding for spacing */
  }

  .cart-counter {
    top: 0px;
    right: -1px;
    min-width: 30%;
  }

  .small-items {
    display: inline !important;
    /* width: 20px; */
  }

  .large-items {
    display: none !important;
  }

  .container-fluid {
    grid-template-columns: 2fr 4fr 1fr;
    gap: 1rem;
  }

  /* Adjust individual icon container */
  .nav-icon-container {
    margin: 0;
    /* Remove extra margins */
  }

  /* Ensure icons have a proper size */
  .nav-icons {
    font-size: 1.5rem;
    /* Keep icons size appropriate */
  }
}

@media (min-width: 850px) and (max-width: 1100px) {
  .navbar {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  /* .logo,
  .navbar-brand {
    width: 55% !important;
    height: 100%;
  } */

  .container-fluid {
    grid-template-columns: 2fr 4fr 1fr;
  }
}

@media (min-width: 903px) and (max-width: 1250px) {
  .container-fluid {
    grid-template-columns: 1fr 4fr 2fr;
  }
}

/* New work css */
.dropdown-submenu>a::after {
  content: "";
  /* float: right; */
  margin-left: 10px;
  font-weight: bold;
  transform: rotate(90deg);
}

#navbarDropdown:hover+.dropdown-menu,
.dropdown-menu:hover {
  display: block;
  /* Show the dropdown menu on hover */
}

/* .navbar-nav .dropdown-menu .dropdown-submenu > a::after {
  transform: rotate(-90deg);
} */

.dropdown-submenu .dropdown-menu {
  display: none;
}

.dropdown-submenu:hover>.dropdown-menu {
  display: block;
  top: 0;
  left: 100%;
  margin-top: -1px;
}

/* Adjust dropdown menu for mobile view */
@media (max-width: 850px) {
  .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    background-color: transparent;
  }

  .dropdown-submenu>a::after {
    /* content: "+"; */
    /* float: right; */
    /* transform: rotate(0deg); */
  }

  .dropdown-submenu:hover>.dropdown-menu {
    display: block;
    position: static;
    float: none;
    margin-top: 0;
  }

  .navbar-nav .dropdown-menu .dropdown-submenu>a::after {
    transform: rotate(0deg);
  }
}