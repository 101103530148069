/* Product.css */

.product-component-card {
    background-color: #ffffff;
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    width: 100%; 
    max-height: 400px;
    display: flex;
    
    flex-direction: column;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    border: none;
}

.product-component-card:hover {
    transform: translateY(-5px) scale(1.02);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.product-image-section {
    height: 200px;
    /* Image occupies 40% of the card's height */
    position: relative;
}

.product-component-image {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensures the image is contained within the box without overflow */
    transition: transform 0.3s ease;
}

.product-component-card:hover .product-component-image {
    transform: scale(1.05);
}

.product-details-section {
        
    height: 200px;
    /* Details occupy the remaining 60% */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    background-color: #f9f9f9;
}

.product-component-info {
    text-align: center;
}

.product-component-title {
    font-family: 'Jost', sans-serif;
    font-size: 1.1rem;
    margin-bottom: 8px;
    color: #333333;
    height: 38px;
    /* Fixed height to ensure consistency */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.product-component-rating {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
}

.star {
    color: #ccc;
    margin: 0 2px;
    font-size: 1rem;
}

.star.filled {
    color: #f39c12;
}

.product-component-pricing {
    text-align: center;
    margin-bottom: 12px;
}

.product-component-price {
    font-size: 1rem;
    font-family: 'Jost', sans-serif;
    color: #045149;
    margin-bottom: 4px;
}

.original-price {
    text-decoration: line-through;
    color: #999999;
    font-size: 0.7rem;
    margin-right: 8px;
}

.discounted-price {
    color: #e63946;
    font-weight: bold;
}

.discount-text {
    font-size: 0.85rem;
    color: #e63946;
}

.btn-wrap {
    text-align: center;
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: #006AA7;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 24px;
    font-family: 'Jost', sans-serif;
    font-size: 0.95rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn:hover {
    background-color: #004f7a;
    transform: translateY(-2px);
}

.cart-icon {
    margin-right: 8px;
    font-size: 1.2rem;
}

@media (max-width: 1024px) {
    .product-component-card {
        flex-direction: column;
        max-height: 100%; /* Allows flexible height in rows */
    }

    .product-image-section {
        height: 180px;
    }
}

@media (max-width: 768px) {
    .product-component-card {
        flex-direction: column;
        max-height: 500px;
        width: 100%; /* Maintain full width */
    }

    .product-image-section {
        height: 220px;
    }
}

@media (max-width: 480px) {
    .product-component-card {
        flex-direction: column;
        width: 100%; /* Ensure full width on mobile */
        max-height: none; /* Allow natural height growth */
    }

    .product-image-section {
        height: 200px; /* Maintain consistency in smaller view */
    }
}