/* Returns.css */

.returns-container {
    padding: 2rem;
    max-width: 600px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    text-align: center;
    color: #333;
}

.return-card {
    margin: 1rem 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    transition: box-shadow 0.3s;
}

.return-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.card-content {
    padding: 1rem;
}

.card-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

.card-subtitle {
    font-size: 1.1rem;
    color: #555;
}

.card-text {
    color: #777;
}

.no-returns {
    font-size: 1.2rem;
    color: #777;
    text-align: center;
}
