.mega-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 90%;
  transform: translateX(-50%);
  background-color: #ffffff;
  padding: 20px;
  display: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  opacity: 0;
  transform: translate(-50%, -20px);
  height: 60vh;
}

.category-icon-navbar {
  /* margin-right: 8px; */
  vertical-align: middle;
  height: 50px;
  /* margin-right: 20px; */
}

.mega-menu.show {
  display: flex;
  opacity: 1;
  transform: translate(-50%, 0);
}

.mega-menu-content {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.categories-column {
  width: 20%;
  max-height: 100%;
  overflow-y: auto;
  border-right: 1px solid #e0e0e0;
  padding: 10px;
  transition: transform 0.3s ease;
}

.category-item {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.category-item:hover {
  background-color: #f0f0f0;
}

.subcategories-column {
  width: 80%;
  padding: 10px;
  max-height: 100%;
  overflow-y: auto;
  border-left: 1px solid #e0e0e0;
}

.subcategory-item {
  padding: 5px 0;
}

.subcategory-item a {
  text-decoration: none;
  color: #555;
  transition: color 0.3s;
}

.subcategory-item a:hover {
  color: #007bff;
  text-decoration: underline;
}

.subcategory-placeholder {
  color: #888;
  padding: 10px;
  font-style: italic;
}

/* Custom Scrollbar */
.categories-column::-webkit-scrollbar,
.subcategories-column::-webkit-scrollbar {
  width: 8px;
}

.categories-column::-webkit-scrollbar-thumb,
.subcategories-column::-webkit-scrollbar-thumb {
  background-color: #b6babe;
  border-radius: 4px;
}

.categories-column::-webkit-scrollbar-thumb:hover,
.subcategories-column::-webkit-scrollbar-thumb:hover {
  background-color: #b6babe;
}

.categories-column::-webkit-scrollbar-track,
.subcategories-column::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding-top: 15px;
  margin-top: 10px;
  border-top: 1px solid #e0e0e0;
}

.pagination-controls button {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 3px;
  transition: background 0.3s;
  font-size: 0.9rem;
}

.pagination-controls button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.pagination-controls button:hover:not(:disabled) {
  background: #0056b3;
}

.subcategory-icon {
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .mega-menu-content {
    flex-direction: column;
    overflow-y: auto;
    /* Allow vertical scrolling */
  }

  .categories-column {
    width: 100%;
    max-height: 150px;
    overflow-y: scroll;
    border-right: none;
    border-bottom: 1px solid #e0e0e0;
  }

  .subcategories-column {
    width: 100%;
    max-height: 150px;
  }

  .mega-menu.show {
    width: 100%;
    left: 0;
    transform: translateX(0);
  }
}

@media (max-width: 480px) {
  .mega-menu {
    padding: 10px;
  }

  .categories-column,
  .subcategories-column {
    max-height: 120px;
  }

  .category-item,
  .subcategory-item {
    font-size: 0.85rem;
    /* Slightly smaller font for better readability */
  }
}