/* General Styling for Product Information Component */
.product-information {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
}

/* Product Attributes Bar */
.product-attributes-bar {
  display: flex;
  gap: 10px;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  margin-top: 10px;
}

.product-attribute {
  /* padding: 8px 15px; */
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  padding: 5px 10px 5px 10px;
}

.attribute-text {
  margin-left: 5px;
}

/* Product Name, Brand, and Price */
.product-name {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
  text-align: left;
}

.product-brand {
  font-size: 1.4rem;
  color: #555;
  /* margin-bottom: 1.5rem; */
  text-align: left;
}

.product-price {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: left;
}

/* Rating Component */
.product-rating {
  margin: 1.5rem 0;
  text-align: center;
}

/* Variation and Color Selectors */
.product-variation,
.product-color {
  margin: 1.5rem 0;
  text-align: left;
}

.variation-buttons button,
.color-buttons button {
  margin: 0.5rem;
}

/* Quantity Selector */
.product-quantity {
  margin: 1.5rem 0;
  text-align: left;
}

.product-quantity h3 {
  font-size: 1.2rem;
  font-weight: normal;
  /* margin-bottom: 0.5rem; */
}

/* Voucher Style for Discounts */
.voucher {
  background: linear-gradient(90deg, #ffe259 0%, #ffa751 100%);
  padding: 15px;
  border-radius: 8px;
  margin: 15px 0;
  text-align: center;
  font-weight: bold;
  color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Accordion for Additional Information */
.MuiAccordion-root {
  margin: 1.5rem 0;
  box-shadow: none;
}

.MuiAccordionSummary-root {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 10px 15px;
}

.MuiAccordionDetails-root {
  padding: 15px;
}

/* Buttons and Inputs */
.MuiButton-root {
  margin: 0.5rem;
}

.MuiTextField-root {
  width: 100px;
}

/* Responsive Design Adjustments */
@media (max-width: 850px) {
  .product-attributes-bar {
    flex-wrap: wrap;
    justify-content: start;
    text-align: center;
  }

  .product-name {
    font-size: 2rem;
  }

  .product-brand {
    font-size: 1.2rem;
  }

  .product-price {
    font-size: 1.6rem;
  }

  .product-variation,
  .product-color,
  .product-quantity {
    text-align: center;
  }

  .voucher {
    font-size: 1rem;
  }
}

/* Media Query for Medium Screens */
@media (min-width: 850px) and (max-width: 1100px) {
  .product-other-details-card {
    width: 100%;
    margin-bottom: 2rem;
  }

  .product-other-details-cards-container {
    display: block;
  }
}
