.account-section {
  padding: 4rem 10rem;
}

.account-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 2rem;
  width: 100%;
}

.account-container .account-details {
  border: 1px solid #ffffff;
  margin-bottom: 1rem;
}

.account-details {
  background-color: #ffffff;
  border-radius: 2rem;
}

.profile-name {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
}

.profile-name div h1 {
  font-size: 1.2rem;
  font-weight: 500 !important;
}

.profile-name div p {
  font-size: 0.8rem;
  color: gray;
  margin-bottom: 0 !important;
}

.profile-sidebar-container {
  padding: 1rem;
}

.profile-sidebar-items {
  padding: 1rem;
  font-size: 1rem;
  font-weight: 500 !important;
  color: #757575;
}

.profile-head {
  cursor: pointer;
}

.profile-side-bar-subitems {
  padding: 0.5rem 0;
  color: #000;
}

.profile-side-bar-subitems div {
  padding: 0.3rem 2rem;
  cursor: pointer;
}

.color-light {
  border-top: 1px solid #b9b4b4 !important;
}

.gender-radio-btns {
  display: flex !important;
  flex-direction: row !important;
}

.personal-info-container {
  padding: 2.5rem;
  width: 100%;
  grid-column: span 2; /* Makes the personal info container span the full width */
}

.fixed-width-input {
  width: 100% !important;
}

.MuiFormControl-root {
  width: 100% !important;
}

/* Mobile responsiveness */
@media (max-width: 850px) {
  .account-section {
    padding: 2rem;
  }

  .account-container {
    grid-template-columns: 1fr; /* Single column layout for smaller screens */
  }

  .personal-info-container {
    padding: 1.5rem;
  }
}

/* Tablet/medium screens responsiveness */
@media (min-width: 850px) and (max-width: 1100px) {
  .account-section {
    padding: 2rem 3rem;
  }

  .profile-sidebar-container,
  .profile-name {
    padding: 1rem;
  }

  .personal-info-container {
    padding: 2rem;
  }
}

/* Large screens */
@media (min-width: 1100px) {
  .account-section {
    padding: 4rem 10rem;
  }
}
